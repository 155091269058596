

function ScrollHeader(el)
{
  this.el = el;
  this.init();
}

ScrollHeader.prototype.init = function()
{
  this.scaleStart = 0.9;
  this.scaleDiff = 0.1;
  this.isScaling = true; // make sure we set initial scale correctly

  this.resize();
  this.reposition();
};

ScrollHeader.prototype.resize = function(windowWidth, windowHeight, scrollPos)
{
  windowWidth = windowWidth || window.innerWidth;
  windowHeight = windowHeight || window.innerHeight;
  scrollPos = scrollPos || window.scrollY;

  if (!this.isResizing)
  {
    this.isResizing = true;

    // remove custom styles so we can get metrics based on original styles
    Object.assign(this.el.style, {
      position: '',
      top: '',
    });

    var self = this;
    setTimeout(function() {
      self.resize(windowWidth, windowHeight);
      self.isResizing = false;
    }, 10);

    return;
  }

  var boundingRect = this.el.getBoundingClientRect(),
    scaleTop = scrollPos + boundingRect.top - windowHeight,
    scaleDistance = boundingRect.height,
    scaleBottom = scaleTop + scaleDistance;

  Object.assign(this.el.style, {
    position: 'sticky',
    top: `${(windowHeight / 2) - (boundingRect.height / 2)}px`,
  });

  this.boundingRect = boundingRect;
  this.scaleTop = scaleTop;
  this.scaleDistance = scaleDistance;
  this.scaleBottom = scaleBottom;

  this.isResizing = false;
};

ScrollHeader.prototype.reposition = function(scrollPos, scrollDir)
{
  scrollPos = scrollPos || window.scrollY;

  var scaleStart = 0.9,
    scaleDiff = 0.1;

  if (scrollPos > this.scaleTop && scrollPos <= this.scaleBottom)
  {
    this.isScaling = true;

    var scaleFactor = ((scrollPos - this.scaleTop) / this.scaleDistance),
      scale = scaleStart + (scaleDiff * scaleFactor);

    this.el.style.transform = `scale(${scale})`;
  }

  else if (this.isScaling && scrollPos <= this.scaleTop) {
    this.el.style.transform = `scale(${scaleStart})`;
    this.isScaling = false;
  }

  else if (this.isScaling && scrollPos > this.scaleBottom) {
    this.el.style.transform = `scale(1)`;
    this.isScaling = false;
  }

  // else if (this.scaling && scrollPos > this.scaleBottom)
  // {
  //   this.scaling = false;
  //   this.el.style.transform = `scale(${scaleStart})`;
  // }

  // else if (this.scaling && scrollPos <= this.scaleTop)
  // {
  //   this.scaling = false;
  //   this.el.style.transform = `scale(1)`;
  // }

};

var scrollHeaders = [];
var scrollHeadersInitialized = false;

function initScrollHeaders()
{
  scrollHeadersInitialized = true;
  document.querySelectorAll('.js-scroll-header').forEach(function(scrollHeader) {
    scrollHeaders.push(new ScrollHeader(scrollHeader));
  });
}

var scrollTicking = false;
var lastScrollPos = 0;
var scrollPos = lastScrollPos;
var scrollDir = null;

var resizeTicking = false;
var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;

document.addEventListener('scroll', function(e)
{
  if (!scrollTicking)
  {
    window.requestAnimationFrame(function()
    {
      lastScrollPos = scrollPos;
      scrollPos = window.scrollY;
      scrollDir = (scrollPos > lastScrollPos ? 'down' : 'up');

      scrollHeaders.forEach(function(scrollHeader) {
        scrollHeader.reposition(scrollPos, scrollDir);
      });

      scrollTicking = false;
    });

    scrollTicking = true;
  }
});

window.addEventListener('resize', function()
{
  if (!resizeTicking)
  {
    window.requestAnimationFrame(function()
    {
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;

      scrollHeaders.forEach(function(scrollHeader) {
        scrollHeader.resize(windowWidth, windowHeight, scrollPos);
      });
      resizeTicking = false;
    });

    resizeTicking = true;
  }
});

initScrollHeaders();
